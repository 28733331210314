import { Helmet } from 'react-helmet-async'

const Meta = ({title, description, keywords}) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='keyword' content={keywords} />
    </Helmet>
  )
}

Meta.defaultProps = {
    title: 'Welcome To Shred Central',
    description: 'We sell the best snowboard gear for cheap',
    keywords: 'snowboard, gloves, clothes'
}
export default Meta