import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import { toast } from 'react-toastify'
import { useGetUserDetailsQuery, useUpdateUserMutation } from '../../slices/usersApiSlice'
const UserEditScreen = () => {
    //Reminder that useParams is a hook that returns an object of key/value pairs of URL parameters. Anything with :id in the URL will be a key in the object
    const { id: userId } = useParams()

    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)

    const { data: user, isLoading, refetch, error } = useGetUserDetailsQuery(userId)

    const [updateUser, { isLoading: loadingUpdate}] = useUpdateUserMutation()


    useEffect(() => {
        if (user) {
            setName(user.name)
            setEmail(user.email)
            setIsAdmin(user.isAdmin)
        }
    
    }, [user])

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
          await updateUser({
            userId,
            name,
            email,
            isAdmin
          }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
          toast.success('User updated');
          refetch();
          navigate('/admin/userlist');
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        }
    }
  return <div style={{ marginTop: '65px' }}>
    <Link to='/admin/userlist' className='btn btn-light my-3'>Go Back</Link>
    <FormContainer >
        <h1> Edit User </h1>
        {loadingUpdate && <Loader />}

        { isLoading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : (
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type='text' placeholder='Enter name' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type='email' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='isAdmin'>
                    <Form.Check type='checkbox' className='my-2' label='Is Admin' checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)}></Form.Check>
                </Form.Group>        
                <Button type='submit' className='my-2' variant='primary'>
                    Update
                </Button>
            </Form>
        )}
    </FormContainer>
  </div>
}

export default UserEditScreen