import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Paginate = ({ pages, page, isAdmin = false, keyword = '', category = ''}) => {
  return (
    pages > 1 && (
      <Pagination>
        {/* Create route to each page */}
        {[...Array(pages).keys()].map((x) => (
          <LinkContainer key={x + 1} to={
            !isAdmin ? keyword ? `/search/${keyword}/page/${x + 1}` : category
            ? `/search/${category}/page/${x + 1}` : `/page/${x + 1}` : `/admin/productlist/${x + 1}`
          }>
            {/* Used to show what page we're on */}
            <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
          </LinkContainer>
        ))}
      </Pagination>
    )
  )
}

export default Paginate