import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './slices/apiSlice'
import  cartSliceReducer from './slices/cartSlice'
import authSliceReducer from './slices/authSlice'

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        cart: cartSliceReducer,
        auth: authSliceReducer
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(apiSlice.middleware)
    },
    devTools: process.env.NODE_ENV !== 'production'
})

export default store